import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { handleRadioButtonChange } from './complaint_form_notify_controller'

export default class extends Controller {
  static targets = [
    'docType',
    'docId',
    'name',
    'id',
    'email_answer',
    'phone_answer',
    'isHidden',
    'notify_me_phone',
    'notify_me_phone_radioButton',
    'notify_me_email',
    'notify_me_email_radioButton',
    'notify_answer_email',
    'notify_answer_email_radioButton',
    'notify_answer_phone',
    'notify_answer_phone_radioButton',
    'notify_answer_email_from_branch',
    'notify_answer_email_from_branch_radioButton',
    'notify_answer_phone_from_branch',
    'notify_answer_phone_from_branch_radioButton',
    'validateGroup',
    'validate_boton',
    'alert_document',
    'alert_name',
    //primer nivel de opciones
    'radio_btn_send_reply',
    'radio_container_send_reply',
    //segundo nivel de opciones
    'radio_btn_notify_answer',
    'radio_container_notify_answer',
    //notificaciones
    'radio_btn_notify_me',
    'radio_container_notify_me'
  ]
  // 'region', 'province', 'ubigeo', 'address'

  connect() {
    this.docIdTarget.setAttribute('readonly', '')
    this.nameTarget.setAttribute('readonly', '')
  }

  selectDocument(event) {
    const controlTypeDocument = this.docTypeTarget
    const typeDocument = controlTypeDocument.value
    const controlDocument = this.docIdTarget
    const groupValidate = this.validateGroupTarget
    const botonValidate = this.validate_botonTarget
    const alertName = this.alert_nameTarget
    alertName.innerHTML = ''

    let optionSelected = this.getOptionSelected(controlTypeDocument)
    if (typeDocument) {
      controlDocument.removeAttribute('readonly')
      groupValidate.classList.remove('hidden')
    } else {
      controlDocument.setAttribute('readonly', '')
      groupValidate.classList.add('hidden')
    }

    botonValidate.innerText = `Validar ${optionSelected.innerText}`

    const docsReadonly = ['dni', 'immigration_card']
    if (typeDocument != '') {
      docsReadonly.includes(typeDocument)
        ? this.nameTarget.setAttribute('readonly', '')
        : this.nameTarget.removeAttribute('readonly')
    } else {
      this.nameTarget.setAttribute('readonly', '')
    }

    this.actionDocument(typeDocument)
  }

  actionDocument(type) {
    const controlDocument = this.docIdTarget
    const alertDocument = this.alert_documentTarget
    const controlName = this.nameTarget
    controlName.value = ''

    const document = this.getTypeDocument(type)
    const regex = document ? new RegExp(document.regex) : ''
    this.setFormatDocument(controlDocument.value, regex, controlDocument, alertDocument, document)

    controlDocument.addEventListener('keyup', e => {
      const value = controlDocument.value
      this.setFormatDocument(value, regex, controlDocument, alertDocument, document)
    })
  }

  getTypeDocument(type) {
    const types = [
      { document: 'dni', regex: /^[0-9]{8}$/, char: '8', validate: true },
      { document: 'immigration_card', regex: /^[0-9]{9,10}$/, char: '9 ó 10', validate: true },
      { document: 'passport', regex: /^[0-9A-Za-z]{6,12}$/, char: '6 ó 12', validate: false },
      { document: 'other', regex: /^[0-9A-Za-z]{6,12}$/, char: '6 ó 12', validate: false },
      { document: 'ruc', regex: /^[0-9]{11}$/, char: '11', validate: true },
      { document: 'diplomatic_id', regex: /^[0-9A-Za-z]{9}$/, char: '9', validate: true },
      { document: 'temporary_permit', regex: /^[0-9A-Za-z]{9}$/, char: '9', validate: true },
      { document: 'andean_card', regex: /^[0-9A-Za-z]{9}$/, char: '9', validate: true }
    ]
    return types.find(item => item.document == type)
  }

  setFormatDocument(value, regex, controlDocument, alertDocument, document) {
    const match = regex ? !regex.test(value) : false
    alertDocument.innerHTML = ''
    this.addInpuBorderWarning(controlDocument)
    if (value.length > 0 && match) {
      alertDocument.innerHTML = `Debe tener un formato valido<br>${document.char} digitos`
      this.addInpuBorderWarning(controlDocument, true)
    }
  }

  search(event) {
    const controlTypeDocument = this.docTypeTarget
    const documentType = controlTypeDocument.value
    const controlDocument = this.docIdTarget
    const documentValue = controlDocument.value
    const botonValidate = this.validate_botonTarget
    const alertDocument = this.alert_documentTarget
    const alertName = this.alert_nameTarget
    alertName.innerHTML = ''

    if (documentValue.length == 0) {
      this.addInpuBorderWarning(controlDocument, true)
      alertDocument.innerHTML = 'El número de documento es requerido'
      return
    }

    this.addInpuBorderWarning(controlDocument)
    alertDocument.innerHTML = ''

    const recaptchaElement = document.getElementById('g-recaptcha-response-data-search-people')
    const siteKey = recaptchaElement.dataset.sitekey

    if (!siteKey) {
      alertDocument.innerHTML = 'Error al validar el captcha'
      return
    }

    grecaptcha.execute(siteKey, { action: 'search_people' }).then(recaptchaResponse => {
      const document = this.getTypeDocument(documentType)
      const regex = document ? new RegExp(document.regex) : ''
      this.setFormatDocument(documentValue, regex, controlDocument, alertDocument, document)
      if (alertDocument.innerHTML) return

      let optionSelected = this.getOptionSelected(controlTypeDocument)

      this.resetValues()

      const ajaxUrl = this.element.getAttribute('data-search-url')
      if (documentValue && documentType) {
        botonValidate.innerText = 'Validando...'
        botonValidate.classList.add('control-loading')
        Rails.ajax({
          type: 'GET',
          url: ajaxUrl,
          data: `doc_id=${documentValue}&doc_type=${documentType}&g_recaptcha_response=${recaptchaResponse}`,
          success: data => {
            this.refreshValues(data)
            botonValidate.classList.remove('control-loading')
            if (data.length == 0) {
              if (document.validate) {
                alertDocument.innerHTML = 'No se ha encontrado información<br>Verifica tu número de documento'
              } else {
                alertName.innerHTML = 'No se ha encontrado información, ingresa tus datos'
              }
            }

            botonValidate.innerText = `Validar ${optionSelected.innerText}`
          },
          error: data => {
            botonValidate.innerText = 'Error. Validar otra vez.'
            botonValidate.classList.remove('control-loading')
          }
        })
      }
    })
  }

  addInpuBorderWarning(element, state = false) {
    element.removeAttribute('style')
    if (state) {
      element.style.borderColor = 'rgb(202, 138, 4)'
    }
  }

  getOptionSelected(select) {
    let optionSelected
    for (let item of select.options) {
      if (item.selected) {
        optionSelected = item
      }
    }
    return optionSelected
  }

  refreshValues(data) {
    let notify_answer = 'none'
    if (data && data.length == 1) {
      data = data[0]
      this.idTarget.value = data.id
      this.nameTarget.value = data.name

      if (data.email_answer.length > 0) {
        this.email_answerTarget.setAttribute('placeholder', data.email_answer)
        this.toggleRadioBtn('to_email', this.radio_btn_send_replyTargets)
        this.toggleContainer('to_email', this.radio_container_send_replyTargets)
        notify_answer = 'email'
        // console.log('opcion1 email')
      } else if (data.phone_answer.length > 0) {
        this.phone_answerTarget.setAttribute('placeholder', data.phone_answer)
        this.toggleRadioBtn('to_phone', this.radio_btn_send_replyTargets)
        this.toggleContainer('to_phone', this.radio_container_send_replyTargets)
        notify_answer = 'phone'
        // console.log('opcion4 fono')
      }
      if (notify_answer === 'none' && data.notify_answer_email.length > 0) {
        // console.log('opcion3 email')
        this.notify_answer_emailTarget.setAttribute('placeholder', data.notify_answer_email)
        this.toggleRadioBtn('to_home', this.radio_btn_send_replyTargets)
        this.toggleContainer('to_home', this.radio_container_send_replyTargets)
        this.toggleSecondOptionsSendReply(
          'notify_answer_email',
          this.radio_btn_notify_answerTargets,
          this.radio_container_notify_answerTargets
        )
        notify_answer = 'notify_answer_email_from_home'
      } else if (notify_answer === 'none' && data.notify_answer_phone.length > 0) {
        // console.log('opcion3 fono')
        this.notify_answer_phoneTarget.setAttribute('placeholder', data.notify_answer_phone)
        this.toggleRadioBtn('to_home', this.radio_btn_send_replyTargets)
        this.toggleContainer('to_home', this.radio_container_send_replyTargets)
        this.toggleSecondOptionsSendReply(
          'notify_answer_phone',
          this.radio_btn_notify_answerTargets,
          this.radio_container_notify_answerTargets
        )
        notify_answer = 'notify_answer_phone_from_home'
      }
      if (notify_answer === 'none' && data.notify_answer_email_from_branch.length > 0) {
        // console.log('opcion2 email')
        this.notify_answer_email_from_branchTarget.setAttribute('placeholder', data.notify_answer_email)
        this.toggleRadioBtn('to_branch', this.radio_btn_send_replyTargets)
        this.toggleContainer('to_branch', this.radio_container_send_replyTargets)
        this.toggleSecondOptionsSendReply(
          'notify_answer_email_from_branch',
          this.radio_btn_notify_answerTargets,
          this.radio_container_notify_answerTargets
        )
        notify_answer = 'notify_answer_email_from_branch'
      } else if (notify_answer === 'none' && data.notify_answer_phone_from_branch.length > 0) {
        // console.log('opcion2 fono')
        this.notify_answer_phone_from_branchTarget.setAttribute('placeholder', data.notify_answer_phone)
        this.toggleRadioBtn('to_branch', this.radio_btn_send_replyTargets)
        this.toggleContainer('to_branch', this.radio_container_send_replyTargets)
        this.toggleSecondOptionsSendReply(
          'notify_answer_phone_from_branch',
          this.radio_btn_notify_answerTargets,
          this.radio_container_notify_answerTargets
        )
        notify_answer = 'notify_answer_phone_from_home'
      }

      if (data.notify_me_email.length > 0) {
        // console.log('form5 email')
        this.notify_me_emailTarget.setAttribute('placeholder', data.notify_me_email)
        this.notify_me_emailTarget.value = ''
        this.hideContainersOptionsNotify()
      } else if (data.notify_me_phone.length > 0) {
        // console.log('form5 fono')
        this.notify_me_phoneTarget.setAttribute('placeholder', data.notify_me_phone)
        this.notify_me_phoneTarget.value = ''
        this.hideContainersOptionsNotify()
      }
    }
  }

  toggleContainer(key, container_targets) {
    container_targets.forEach(el => {
      if (el.dataset.key == key) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })
  }

  toggleRadioBtn(key, radio_targets) {
    radio_targets.forEach(el => {
      if (el.dataset.key == key) {
        el.checked = true
        handleRadioButtonChange(key)
      } else {
        el.checked = false
      }
    })
  }

  toggleSecondOptionsSendReply(key, radio_targets, container_targets) {
    this.toggleRadioBtn(key, radio_targets)
    this.toggleContainer(key, container_targets)
  }

  hideContainersOptionsNotify() {
    this.radio_btn_notify_meTargets.forEach(el => {
      el.checked = false
    })
    this.radio_container_notify_meTargets.forEach(el => {
      el.classList.add('hidden')
    })
  }

  resetValues() {
    this.nameTarget.value = null
    this.idTarget.value = null
    this.email_answerTarget.setAttribute('placeholder', 'email@ejemplo.com')
    this.phone_answerTarget.setAttribute('placeholder', '9xxyyyzzz')
    this.notify_me_emailTarget.setAttribute('placeholder', 'email@ejemplo.com')
    this.notify_me_phoneTarget.setAttribute('placeholder', '9xxyyyzzz')
    this.notify_answer_emailTarget.setAttribute('placeholder', 'email@ejemplo.com')
    this.notify_answer_phoneTarget.setAttribute('placeholder', '9xxyyyzzz')

    // this.notify_answer_phoneTarget.setAttribute("placeholder", "9xxyyyzzz");
    // this.addressTarget.setAttribute('placeholder', 'dirección')
    // this.regionTarget.value = null
    // this.provinceTarget.value = null
    // this.ubigeoTarget.value = null
  }
}
